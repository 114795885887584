import type { HeadlineResponse } from '@on3/api';
import { ResizedImage } from 'components/Image/ResizedImage';
import { TextLink } from 'components/UI/Link/TextLink';
import { Text } from 'components/UI/Typography/Text';

import styles from './ArticleFeedAlternate.module.scss';

export const ArticleFeedAlternate = ({
  featuredImage,
  title,
  fullUrl,
}: HeadlineResponse) => {
  return (
    <article className={styles.container}>
      {featuredImage && (
        <TextLink href={fullUrl}>
          <ResizedImage
            {...featuredImage}
            className={styles.image}
            fit="cover"
            gravity="0.5x0.5"
            height={100}
            quality={90}
            width={100}
          />
        </TextLink>
      )}

      <div className={styles.text}>
        <TextLink href={fullUrl}>
          <Text className={styles.title} variant="h6">
            {title}
          </Text>
        </TextLink>
      </div>
    </article>
  );
};
