import * as React from 'react';
import { SVGProps } from 'react';

const Svg5GoatsIconFull = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="#3F464A"
      d="m187.8 183.7-1.8 1.5v49.2h118.6c9.1 0 17.5 1.5 25.2 4.4 7.7 2.9 14.6 6.7 20.5 11.8 5.9 5 10.4 10.7 13.9 17.3 3.4 6.6 5 13.6 5 21.3v121.4c0 7.7-1.7 14.8-5 21.3-3.4 6.6-8.1 12.3-13.9 17.3-5.9 5-12.6 8.9-20.5 11.8-7.7 2.9-16.1 4.4-25.2 4.4H146.4c-9.1 0-17.5-1.5-25.2-4.4-7.7-2.9-14.6-6.7-20.5-11.8-5.9-5-10.4-10.7-13.9-17.3-3.4-6.6-5-13.6-5-21.3v-49.9H123v49.9c0 5.4 2.4 10.1 6.9 14.1s10.1 6 16.5 6h158.2c6.4 0 11.9-2 16.5-6 4.5-4 6.9-8.7 6.9-14.1V289.2c0-5.4-2.4-10.1-6.9-13.9-4.5-3.9-10.1-5.7-16.5-5.7H144.7v-121h73.2l-30.1 35.1zm-7.3 198.7h90.7v-64.8H87.4V100.8h174.7l-31.9 35.1H128.9v146.8h175.9c2.4 0 4.2.7 5.7 2.2s2.4 3 2.4 4.5v121.4c0 4.5-2.7 6.7-7.9 6.7H146.7c-4.9 0-7.4-2.2-7.4-6.7v-49.9h41.3v21.5h-.1z"
    />
    <path
      fill="#3F464A"
      d="m399.5 208.9-2-39.8 21.8-17.8s-21.2-40.6-65.8-86c-.3-.3-.7-.7-1.2-1l.2-.3-23.7-18.3-45.7-10.1-54.8 16-14.6 34.6 27.2-18.8 27.4-8.1 20.2 6.7c-24 13.3-23.2 19.7-23.2 19.7 11.1-1 35.9-.5 35.9-.5l5 11.9-120.5 142.1 158.7 23.5 20.5-36.3-33.3-57.3 27.9 4 40 35.8zm-5.2-76.7s-6.6 4.5-16.3 2.2c-9.7-2.2-16.3-20.2-16.3-20.2l32.6 18z"
    />
    <path
      fill="#4DE88C"
      d="M394.3 132.2s-6.6 4.5-16.3 2.2c-9.7-2.2-16.3-20.2-16.3-20.2l32.6 18z"
    />
  </svg>
);

export default Svg5GoatsIconFull;
