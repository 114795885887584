import {
  v1CategoriesHeadlinesDetail2,
  type V1CategoriesHeadlinesDetail2Params,
} from '@on3/api';
import withAuthentication from '@on3/ui-lib/src/hocs/withAuthentication';
import withValidUrl from '@on3/ui-lib/src/hocs/withValidUrl';
import { getFulfilledValue, proxyApi } from '@on3/ui-lib/utils/api';
import type { AxiosError } from 'axios';
import { ProHome, ProHomePageProps } from 'components/Home/Pro/ProHome';
import { PageWrapper } from 'components/Layouts/PageWrapper';
import type { GetServerSideProps } from 'next';

const title = `Pro Sports, Nascar, NFL, NBA, Women's Sports | 5GOATs`;
const description =
  '5 GOATS tracks all the latest news in professional sports, focusing on NFL, NASCAR, culture and sports betting. 5 GOATS is the exclusive professional sports-focused content provider of the On3 Sports Network.';
const ProHomePage = (props: ProHomePageProps) => {
  return (
    <PageWrapper {...props} description={description} title={title}>
      <ProHome {...props} />;
    </PageWrapper>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const on3ProSiteKey = 397;
  const siteData = await withValidUrl.isValid(ctx, on3ProSiteKey);
  const userData = await withAuthentication.isAuth(ctx);

  try {
    const latestParams: V1CategoriesHeadlinesDetail2Params = {
      category: 131,
    };
    const nflParams: V1CategoriesHeadlinesDetail2Params = {
      category: 540541,
    };
    const nascarParams: V1CategoriesHeadlinesDetail2Params = {
      category: 540543,
    };
    const nflDraftParams: V1CategoriesHeadlinesDetail2Params = {
      category: 132,
    };

    const [latestArticles, nflArticles, nascarArticles, nflDraftArticles] =
      await Promise.allSettled([
        v1CategoriesHeadlinesDetail2(proxyApi, latestParams),
        v1CategoriesHeadlinesDetail2(proxyApi, nflParams),
        v1CategoriesHeadlinesDetail2(proxyApi, nascarParams),
        v1CategoriesHeadlinesDetail2(proxyApi, nflDraftParams),
      ]);

    return {
      props: {
        pageType: 'home',
        siteData,
        userData: userData || {},
        latestArticles: getFulfilledValue(latestArticles),
        nflArticles: getFulfilledValue(nflArticles),
        nascarArticles: getFulfilledValue(nascarArticles),
        nflDraftArticles: getFulfilledValue(nflDraftArticles),
      },
    };
  } catch (error) {
    const err: AxiosError<unknown> = error as AxiosError<unknown>;

    console.log(err);

    return { props: {} };
  }
};

export default withValidUrl(ProHomePage);
