import type { HeadlineResponsePagedData } from '@on3/api';
import { forwardSlashCheck } from '@on3/ui-lib/src/utils/forwardSlashCheck';
import { TextLink } from 'components/UI/Link/TextLink';
import { Text } from 'components/UI/Typography/Text';

import { ArticleFeedAlternate } from './ArticleFeedAlternate';
import styles from './FeedListAlternate.module.scss';

interface IViewAllLinkProps {
  url: string;
  text: string;
  title: string;
}

interface IArticleFeedListProps extends HeadlineResponsePagedData {
  section?: string;
  viewAllLinks?: IViewAllLinkProps[];
}

export const FeedListAlternate = ({
  list = [],
  section,
  viewAllLinks,
}: IArticleFeedListProps) => {
  return (
    <section>
      {section && (
        <header className={styles.header}>
          <h3 className={styles.headerText}>{section}</h3>
        </header>
      )}
      {list?.map((item, index) => {
        return <ArticleFeedAlternate {...item} key={index} />;
      })}
      <footer className={styles.footer}>
        {viewAllLinks?.map(({ text, title, url }, index) => {
          const formattedUrl = url ? forwardSlashCheck(url) : '/';

          return (
            <TextLink
              className={styles.footerLink}
              href={formattedUrl}
              key={index}
              title={title}
              variant="subtitle1"
            >
              {text}
            </TextLink>
          );
        })}
      </footer>
    </section>
  );
};
